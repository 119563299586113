<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Category' : 'Create Category'" />
        <div>
          <md-button :to="{ name: 'Product Categories' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate class="md-layout" @submit.prevent="validateUser">
          <md-field :class="getValidationClass('first_name')">
            <label>Title</label>
            <md-input v-model="form.title" required></md-input>
            <span class="md-error" v-if="!$v.form.title.required"
              >Title is required</span
            >
          </md-field>

          <md-field v-if="metadata">
            <label for="parent_id">Parent</label>
            <!-- <md-select v-model="form.parent_id" name="parent_id" id="parent_id">
              <md-option value="" disabled>Select Parent</md-option> -->

            <md-autocomplete
              :md-options="toArr(metadata.parent_categories)"
              v-model="selectedItem"
              @md-changed="setVal"
            >
              <label>Select Parent</label>
              <template slot="md-autocomplete-item" slot-scope="{ item }">{{
                item.title
              }}</template>

              <template slot="md-autocomplete-empty" slot-scope="{ term }">
                No colors matching "{{ term }}"
              </template>
            </md-autocomplete>

            <!-- <md-option
                v-for="(metadata, id) in metadata.parent_categories"
                :key="id"
                :value="id"
                >{{ metadata }}</md-option
              > -->
            <!-- </md-select> -->
          </md-field>

          <md-field>
            <label>Position</label>
            <md-input v-model="form.position" type="number"></md-input>
          </md-field>

          <mdc-button :loading="loading">{{
            id ? "Update Category" : "Create Category"
          }}</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("product");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      parent_id: null,
    },
    loading: false,
    fetching: false,
    metadata: null,
  }),
  validations: {
    form: {
      title: {
        required,
      },
    },
  },
  methods: {
    ...mapActions([
      "createCategory",
      "updateCategory",
      "getCatMetadata",
      "getCat",
    ]),
    setVal(val) {
      const items = this.toArr(this.metadata.parent_categories);
      const item = items.find((i) => {
        return i.title.toLowerCase() == val.toLowerCase();
      });
      this.form.parent_id = item.id;
    },
    toArr(obj) {
      return Object.entries(obj).map(([id, title]) => ({ id, title }));
    },
    async validateUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };

        try {
          this.loading = true;
          let call = this.id
            ? this.updateCategory(data)
            : this.createCategory(data);
          let msg = this.id ? "Category Updated" : "Category Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Product Categories" });
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    async getMeta() {
      this.fetching = true;
      const res = await this.getCatMetadata();
      this.fetching = false;
      this.metadata = res;
    },
  },
  mounted() {
    this.getMeta();
    if (this.id) {
      this.getCat(this.id).then((category) => {
        this.form = {
          id: category.id,
          title: category.title,
          parent_id: category.parent_id,
          position: category.position,
        };
      });
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    selectedItem: {
      get() {
        return this.value;
      },
      set(item) {
        this.form.parent_id;
        this.$emit("input", item.id);
      },
    },
    selectedAttr: {
      get() {
        return this.value;
      },
      set(item) {
        // this.form.parent_id = item.id;
        this.$emit("input", item.id);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
